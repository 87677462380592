import bannerConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/banner/config-aggregator'
import slideConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/slide/config-aggregator'
import sliderConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/slider/config-aggregator'
import tabsConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/tabs/config-aggregator'

import dynamic from 'next/dynamic'

import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import AccordionShimmer from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/accordion/accordion-shimmer'
import BannerShimmer from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/banner/banner-shimmer'
import SliderCoverFlowShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-cover-flow/slider-cover-flow-shimmer'

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const AccordionThin = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/accordion-thin/accordion-thin'
		),
	{ loading: AccordionShimmer }
)

const AccordionElegant = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/accordion-elegant/accordion-elegant'
		),
	{ loading: AccordionShimmer }
)

const BannerInstagram = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-instagram/banner-instagram'
		),
	{ loading: BannerShimmer }
)

const SliderCoverFlow = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/slider-cover-flow/slider-cover-flow'
		),
	{ loading: SliderCoverFlowShimmer }
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const accordionThinConfig = {
	configAggregator: tabsConfigAggregator,
	component: AccordionThin,
}

const accordionElegantConfig = {
	configAggregator: tabsConfigAggregator,
	component: AccordionElegant,
}

const slideInstagramConfig = {
	configAggregator: slideConfigAggregator,
	component: BannerInstagram,
}

const sliderCoverFlowConfig = {
	configAggregator: sliderConfigAggregator,
	component: SliderCoverFlow,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'tabs-accordion-thin': accordionThinConfig,
	'tabs-accordion-elegant': accordionElegantConfig,
	'slide-instagram': slideInstagramConfig,
	'slider-cover-flow': sliderCoverFlowConfig,
}

export default customContentTypes
